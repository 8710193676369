<template>
  <div class="vippage">
    <b-container fluid class="content">
      <b-card v-if="currpage == 'list'">
        <div class="p-0" v-if="viplist">
          <div class="w-100 text-right pr-4 mb-2">
            <el-row>
              <el-col :span="22">
                <el-button type="primary" size="small" @click="add_vip" v-acl:VIPEdit.enable.show>
                  {{ $t("Add VIP") }}
                </el-button>
              </el-col>
              <el-col :span="2">
                <xlsx-workbook>
                  <xlsx-sheet
                    :collection="sheet.data"
                    v-for="sheet in sheets"
                    :key="sheet.name"
                    :sheet-name="sheet.name"
                    @parsed="onParsed"
                  />
                  <xlsx-download filename="Customer List.xlsx" disable-wrapper-click>
                    <template #default="{download}">
                      <el-button type="primary" size="small" @click="export_xlsx(download)" :loading="loadingState">XLSX</el-button>
                    </template>
                  </xlsx-download>
                </xlsx-workbook>
              </el-col>
            </el-row>
          </div>
          <el-table
            stripe
            class="table-responsive table"
            style="width: 100%"
            header-row-class-name="thead-light"
            :data="viplist"
            @row-click="edit_vip"
          >
            <el-table-column
              label="CustomerNo"
              prop="CustomerNo"
              class="p-0"
              min-width="100px"
            >
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("VIP No") }}</div>
                <input type="text" class="vip-input" v-model="cur_CustomerNo" @keyup="get_vip_data_delay">
              </template>
            </el-table-column>
            <el-table-column
              label="CustomerType"
              prop="VIPType"
              class="p-0"
              min-width="100px"
            >
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("VIP Type") }}</div>
                <input type="text" class="vip-input vip-disabled" disabled >
              </template>
            </el-table-column>
            <el-table-column label="Name">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Name") }}</div>
                <input type="text" class="vip-input" v-model="cur_Name" @keyup="get_vip_data_delay">
              </template>
              <template slot-scope="scope">
                {{
                  scope.row.LastName
                    ? scope.row.FirstName + " " + scope.row.LastName
                    : scope.row.FirstName
                }}
              </template>
            </el-table-column>
            <el-table-column label="Phone">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Phone") }}</div>
                <input type="text" class="vip-input" v-model="cur_Phone" @keyup="get_vip_data_delay">
              </template>
              <template slot-scope="scope">
                {{ scope.row.Cell ? scope.row.Cell : "N/A" }}
              </template>
            </el-table-column>
            <el-table-column label="Store" prop="Store">
              <template v-slot:header>
                <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
                <el-select
                  class="p-0 m-0"
                  size="mini"
                  v-model="cur_Store"
                  placeholder=""
                >
                  <el-option v-for="(location, index) in location_option"
                            :key="index"
                            :label="location.LocationName"
                            :value="location.LocationID"
                            @click.native="get_vip_data_delay">
                  </el-option>
                </el-select>
              </template>
              <template v-slot="{ row }">
                <div class="pl-4">{{ locationDict.hasOwnProperty(row.RegLocation) ? locationDict[row.RegLocation].LocationName : '' }}</div>
              </template>
            </el-table-column>
            <el-table-column label="Status" prop="Status">
              <template v-slot:header>
                <div class="w-100 p-0 text-center">{{ $t("Status") }}</div>
                <el-select
                  class="p-0 m-0"
                  size="mini"
                  v-model="cur_Status"
                  placeholder=""
                >
                  <el-option v-for="item in status_option"
                            :key="item.Value"
                            :label="item.Label"
                            :value="item.Value"
                            @click.native="get_vip_data_delay">
                  </el-option>
                </el-select>
              </template>
              <template v-slot="{ row }">
                <div class="pl-4">{{ row.Status==1?'Active':'Unconfirm' }}</div>
              </template>
            </el-table-column>
            <el-table-column label="Email" prop="EMail">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Email") }}</div>
                <input type="text" class="vip-input" v-model="cur_Email" @keyup="get_vip_data_delay">
              </template>
            </el-table-column>
            <el-table-column label="Balance" prop="Balance">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Balance") }}</div>
                <div>
                  <input type="number" class="vip-input" v-model="cur_MinBalance" @keyup="get_vip_data_delay" style="width: 40%; text-align: center;" :placeholder="$t('Min')">
                  {{ "-" }}
                  <input type="number" class="vip-input" v-model="cur_MaxBalance" @keyup="get_vip_data_delay" style="width: 40%; text-align: center;" :placeholder="$t('Max')">
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Points" prop="Points">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Points") }}</div>
                <input type="text" class="vip-input vip-disabled" disabled>
              </template>
            </el-table-column>
          </el-table>
          <div class="mt-2" >
            <b-pagination
              v-model="current_page"
              :total-rows="vip_total"
              :per-page="per_page"
            ></b-pagination>
          </div>
        </div>
        <div class="p-3" v-else>
          {{ $t("No Records") }}
        </div>
      </b-card>
      <b-card v-if="currpage == 'edit'">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("VIP No")}}
              </el-col>
              <el-col :span="16">
                <el-input v-model="cur_vip.CustomerNo" size="mini"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("Type")}}
              </el-col>
              <el-col :span="16">
                <select v-model="cur_vip.VipTypeIDn" @change="viptype_changed">
                  <option v-for="vt in viptypelist" :key="vt.IDn" :value="vt.IDn" >
                    {{ vt.Name }}
                  </option>
                </select>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("Status")}}
              </el-col>
              <el-col :span="16">
                <el-select class="p-0" size="mini" v-model="cur_vip.Status">
                  <el-option label=""  value=""> </el-option>
                  <el-option label="Active"  value="1"> </el-option>
                  <el-option label="UnConfirm"  value="2"> </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("Group VIP")}}
              </el-col>
              <el-col :span="16">
                <el-switch
                  v-model="cur_vip.IsGroupVIP"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#cccccc">
                </el-switch>
                <span v-if="cur_vip.Status==1" class="ml-1">{{ $t("Yes") }}</span>
                <span v-if="cur_vip.Status==0" class="ml-1">{{ $t("No") }}</span>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("Discount Rate")}}
              </el-col>
              <el-col :span="16">
                <el-input v-model="cur_vip.DiscountPercentage" type="number" step=".01" size="mini"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("Max Debit")}}
              </el-col>
              <el-col :span="16">
                <el-input v-model="cur_vip.CreditAllowance" type="number" step=".01" size="mini"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("Expire Day")}}
              </el-col>
              <el-col :span="16">
                <el-date-picker
                  v-model="cur_vip.ExpireDay"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  size="small"
                  placeholder="Expire Day">
                </el-date-picker>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <hr>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("First Name")}}
              </el-col>
              <el-col :span="16">
                <el-input v-model="cur_vip.FirstName" size="mini"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("Last Name")}}
              </el-col>
              <el-col :span="16">
                <el-input v-model="cur_vip.LastName" size="mini"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("Gender")}}
              </el-col>
              <el-col :span="16">
                <select v-model="cur_vip.Gender">
                  <option value="" disabled>-- {{ $t("Gender") }} --</option>
                  <option value="M">{{ $t("Male") }}</option>
                  <option value="F">{{ $t("Female") }}</option>
                </select>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("Birthday")}}
              </el-col>
              <el-col :span="8">
                 <!-- ----------- lisa update 2022/01/13 start ----------- -->
                <!-- <el-date-picker
                  v-model="cur_vip.Birthday"
                  size="mini"
                  type="date"
                  :picker-options="birthday_range"
                  :placeholder="$t('Birthday')">
                </el-date-picker> -->
                <select 
                  v-model="birthday_month"
                  @change="changeMonth('new')"
                >
                  <option value="">--{{ "Month" }}--</option>
                  <option
                    v-for="(bithMon,idx) in monthlist"
                    :key="idx"
                    :value="bithMon.value">
                    {{bithMon.text}}
                  </option>
                </select>
                </el-col>
              <el-col :span="8">
                <select v-model="birthday_date">
                  <option value="">--{{ "Day" }}--</option>
                  <option
                    v-for="(bithDate,idx) in daylist"
                    :key="idx"
                    :value="bithDate.value">
                    {{bithDate.text}}
                  </option>
                </select>
                <!-- ----------- lisa update 2022/01/13 end ----------- -->
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("Tel")}}
              </el-col>
              <el-col :span="16">
                <el-input v-model="cur_vip.TEL" size="mini"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("Ext")}}
              </el-col>
              <el-col :span="16">
                <el-input v-model="cur_vip.Ext" size="mini"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("Cel")}}
              </el-col>
              <el-col :span="16">
                <el-input v-model="cur_vip.Cell" size="mini"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("Fax")}}
              </el-col>
              <el-col :span="16">
                <el-input v-model="cur_vip.FAX" size="mini"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("Email")}}
              </el-col>
              <el-col :span="16">
                <el-input v-model="cur_vip.EMail" size="mini"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("ID#")}}
              </el-col>
              <el-col :span="16">
                <el-input v-model="cur_vip.IDCardNo" size="mini"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("Password")}}
              </el-col>
              <el-col :span="16">
                <el-input v-model="cur_vip.Password" size="mini"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row :gutter="20">
              <el-col :span="8" class="text-right">
                {{ $t("Enroll Location")}}
              </el-col>
              <el-col :span="16">
                <select v-model="cur_vip.RegLocation">
                  <option value="" disabled selected>
                    -- {{ $t("Location") }} --
                  </option>
                  <option
                    v-for="(location, idx) in locationlist"
                    :key="idx"
                    :value="location.LocationID"
                  >
                    {{ location.LocationName }}
                  </option>
                </select>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="24">
            <el-row :gutter="20">
              <el-col :span="2" class="text-right">
                {{ $t("Remarks")}}
              </el-col>
              <el-col :span="22">
                <el-input v-model="cur_vip.Remarks" size="mini"></el-input>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <hr>
        <AddressInput2
          address_type="vip"
          :token="token"
          :user_id="user_id"
          :ReferenceIDn="cur_vip.IDn"
          @address_added="added_address"
          @address_deleted="deleted_address"
        >
        </AddressInput2>
        <el-row class="mt-4">
          <el-col :span="6" :offset="6">
            <el-button type="primary" @click="cancel_edit">
              {{ $t("Back") }}
            </el-button>
          </el-col>
          <el-col :span="6" :offset="6">
            <el-button type="primary" @click="submit_vip">
              {{ $t("Submit VIP") }}
            </el-button>
          </el-col>
        </el-row>

        <el-card class="box-card mt-3" v-if="cur_transcations && (cur_transcations.length>0)">
          <div slot="header" class="clearfix">
            <span>{{ $t('History') }}</span>
          </div>
          <el-row :gutter="20">
            <el-col :span="6">
              Date
            </el-col>
            <el-col :span="6">
              TransactionNo
            </el-col>
            <el-col :span="6">
              Type
            </el-col>
            <el-col :span="6">
              Amount
            </el-col>
            <el-col :span="6">
              Points
            </el-col>
          </el-row>
          <el-row :gutter="20" v-if="trans in cur_transcations" :key="trans.IDn">
            <el-col :span="6">
              {{ String(trans.CreateDate).substr(0, 10) }}
            </el-col>
            <el-col :span="6">
              {{ trans.TransactionNo }}
            </el-col>
            <el-col :span="6">
              {{ trans.TransactionType }}
            </el-col>
            <el-col :span="6">
              {{ trans.TransactionAmount }}
            </el-col>
            <el-col :span="6">
              {{ trans.Points }}
            </el-col>
          </el-row>
        </el-card>

        <!-- <el-tabs type="border-card" style="margin-top: 20px;">
          <el-tab-pane :label="$t('VIP Card')">
            <CardList
              ref="cardlist"
              :inquiry_cards="inquiry_cards"
              style="margin-top: 20px"
              >
            </CardList>
          </el-tab-pane>
        </el-tabs> -->

      </b-card>
    </b-container>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  Collapse,
  CollapseItem,
  DatePicker,
  Switch,
  Select,
  Option,
  Card,
  Row,
  Col,
  Tabs,
  TabPane,
} from "element-ui";

// import InputAutocomplete from "../InputAutocomplete";
// import InputAutocomplete2 from "../InputAutocomplete2";
import AddressInput2 from "../AddressInput2";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import { hasUtilityAuth } from "../../directives/acl";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
// import CardList from "./CardList";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [DatePicker.name]: DatePicker,
    [Switch.name]: Switch,
    [Select.name]: Select,
    [Option.name]: Option,
    [Card.name]: Card,
    [Row.name]: Row,
    [Col.name]: Col,
    [Pagination.name]: Pagination,
    // InputAutocomplete,
    // InputAutocomplete2,
    AddressInput2,
    [flatPicker.name]: flatPicker,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },
  data() {
    return {
      delay_timer: null,
      loadingState: false,

      currpage: "list", //'list',//'edit',
      current_page: 1,
      per_page: 20,
      vip_total: 0,
      token: "",
      user_id: "",

      viplist_full: [],
      viplist: [],
      viptypelist: [],
      saleslist: [],
      locationlist: [],
      locationDict: {},
      address_added: [],

      cur_CustomerNo: "",
      cur_Name: "",
      cur_Phone: "",
      cur_Status: "",
      cur_Store: "",
      cur_Email: "",
      cur_MinBalance: "",
      cur_MaxBalance: "",

      cur_vip: {},
      cur_transcations: [],
      new_vip: {
        IDn: 0,
        CustomerNo: "",
        FirstName: "",
        LastName: "",
        Gender: "",
        Birthday: new Date(),
        ExpireDay: "",
        TEL: "",
        Ext: "",
        Cell: "",
        FAX: "",
        EMail: "",
        IDCardNo: "",
        Password: "",
        Status: 1,
        IsGroupVIP: 1,
        CustomerType: "",
        VipTypeIDn: 0,
        VIPType: "",
        RegLocation: "",
        TotalPurchaseAmount: 0,
        LockTotalPurchaseAmount: 0,
        DiscountPercentage: 0,
        LockDiscountPercentage: 0,
        CreditAllowance: 0,

        Remarks: "",
        CreateBy: "",
        CreateDateTime: null,
        UpdateBy: "",
        UpdateDateTime: null,
        transcations: [],
      },
      cur_reglocation: "",

// ----------- lisa update start 2022/01/13-----------
// update the format of birthday to MM-DD
      // birthday_range: {
      //   disabledDate: (time) => {
      //     // 是否禁用日期选择
      //     let date = new Date(time);
      //     return date.getTime() > Date.now();
      //   }        
      // },
      monthlist:[
        {value:'01', text:'01'}, {value:'02', text:'02'}, {value:'03', text:'03'},
        {value:'04', text:'04'}, {value:'05', text:'05'}, {value:'06', text:'06'},
        {value:'07', text:'07'}, {value:'08', text:'08'}, {value:'09', text:'09'},
        {value:'10', text:'10'}, {value:'11', text:'11'}, {value:'12', text:'12'}
      ],
      daysInBigMonth:[
        {value:'01', text:'01'}, {value:'02', text:'02'}, {value:'03', text:'03'},
        {value:'04', text:'04'}, {value:'05', text:'05'}, {value:'06', text:'06'},
        {value:'07', text:'07'}, {value:'08', text:'08'}, {value:'09', text:'09'},
        {value:'10', text:'10'}, {value:'11', text:'11'}, {value:'12', text:'12'},
        {value:'13', text:'13'}, {value:'14', text:'14'}, {value:'15', text:'15'},
        {value:'16', text:'16'}, {value:'17', text:'17'}, {value:'18', text:'18'},
        {value:'19', text:'19'}, {value:'20', text:'20'}, {value:'21', text:'21'},
        {value:'22', text:'22'}, {value:'23', text:'23'}, {value:'24', text:'24'},
        {value:'25', text:'25'}, {value:'26', text:'26'}, {value:'27', text:'27'},
        {value:'28', text:'28'}, {value:'29', text:'29'}, {value:'30', text:'30'}, {value:'31', text:'31'}
      ],
      daysInSmallMonth:[
        {value:'01', text:'01'}, {value:'02', text:'02'}, {value:'03', text:'03'},
        {value:'04', text:'04'}, {value:'05', text:'05'}, {value:'06', text:'06'},
        {value:'07', text:'07'}, {value:'08', text:'08'}, {value:'09', text:'09'},
        {value:'10', text:'10'}, {value:'11', text:'11'}, {value:'12', text:'12'},
        {value:'13', text:'13'}, {value:'14', text:'14'}, {value:'15', text:'15'},
        {value:'16', text:'16'}, {value:'17', text:'17'}, {value:'18', text:'18'},
        {value:'19', text:'19'}, {value:'20', text:'20'}, {value:'21', text:'21'},
        {value:'22', text:'22'}, {value:'23', text:'23'}, {value:'24', text:'24'},
        {value:'25', text:'25'}, {value:'26', text:'26'}, {value:'27', text:'27'},
        {value:'28', text:'28'}, {value:'29', text:'29'}, {value:'30', text:'30'}
      ],
      daysInFeb:[
        {value:'01', text:'01'}, {value:'02', text:'02'}, {value:'03', text:'03'},
        {value:'04', text:'04'}, {value:'05', text:'05'}, {value:'06', text:'06'},
        {value:'07', text:'07'}, {value:'08', text:'08'}, {value:'09', text:'09'},
        {value:'10', text:'10'}, {value:'11', text:'11'}, {value:'12', text:'12'},
        {value:'13', text:'13'}, {value:'14', text:'14'}, {value:'15', text:'15'},
        {value:'16', text:'16'}, {value:'17', text:'17'}, {value:'18', text:'18'},
        {value:'19', text:'19'}, {value:'20', text:'20'}, {value:'21', text:'21'},
        {value:'22', text:'22'}, {value:'23', text:'23'}, {value:'24', text:'24'},
        {value:'25', text:'25'}, {value:'26', text:'26'}, {value:'27', text:'27'},
        {value:'28', text:'28'}, {value:'29', text:'29'}
      ],
      daylist:[],
      sheets: [
        { name: "Sheet1", data: [[]] },
      ],
      status_option:[
        {"Value": 0, "Label": "Inactive"},
        {"Value": 1, "Label": "Active"},
        {"Value": 2, "Label": "Unconfirm"},
      ],
      location_option:[],
      birthday_month: "",
      birthday_date: "",
// ----------- lisa update end 2022/01/13-----------
      pickr_config: {
        enableTime: false,
        noCalendar: false,
        dateFormat: "Y-m-d",
        // time_24hr: true
      },

      // cards
      inquiry_cards: [],

      isOW: false,
    };
  },
  watch: {
    current_page: function (newval, oldval) {
      this.get_vip_data(0);
    },
  },
  methods: {
    onParsed (sheet) {
      var wscols = [
        {wch:15},  // Column A width
        {wch:10},  // Column B width
        {wch:15}, // Column C width
        {wch:18},  // Column D width
        {wch:10},  // Column E width
        {wch:10},  // Column F width
        {wch:25}, // Column G width
        {wch:12},  // Column H width
        {wch:12},  // Column I width
      ];
      sheet['!cols'] = wscols;
    },
    hasUtilityAuth,
    // ----------- lisa add start 2022/01/13-----------
    changeMonth(from) {
      if(from == 'new'){
        this.birthday_date = "";
      }
      this.daylist = [];
      if(this.birthday_month){
        if(this.birthday_month == '02'){
            this.daylist = this.daysInFeb;
        }else if( this.birthday_month == '04' || this.birthday_month == '06'
                  || this.birthday_month == '09' || this.birthday_month == '11' ){

                    this.daylist = this.daysInSmallMonth;
        }else{
          this.daylist = this.daysInBigMonth;
        }
      }      
    },
    // ----------- lisa add end 2022/01/13-----------

    viptype_changed() {
      for (var i=0; i<this.viptypelist.length; i++) {
        if (this.viptypelist[i].IDn == this.cur_vip.VipTypeIDn) {
          this.cur_vip.DiscountPercentage = parseFloat(this.viptypelist[i].Discount);
          this.cur_vip.VIPType = this.viptypelist[i].Name;
        }
      }
    },
    get_vip_data_delay() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }
      var that = this;
      this.delay_timer = setTimeout(() => {
        that.get_vip_data(0);
      }, 400);
    },
    async export_xlsx(download) {
      this.loadingState = true;
      const limit = 300;
      let offset = 0;
      this.viplist_full = [];
      const promises = [];
      while (offset < this.vip_total) {
        promises.push(this.get_viplist_full(offset, limit));
        offset += limit;
      }
      await Promise.all(promises);
      await this.fillup_sheet();
      
      this.$nextTick(async () => {
        setTimeout(() => {
          this.loadingState = false;
          download();
        }, "50");
      });
    },
    async get_viplist_full(offset, limit) {
      return new Promise(async (resolve, reject) => {
        var bodyFormData = new FormData();
        bodyFormData.append("token", this.token);
        bodyFormData.append("user_id", this.user_id);
        if (this.cur_CustomerNo) {
          bodyFormData.append("CustomerNo", this.cur_CustomerNo);
        }
        if (this.cur_Name) {
          bodyFormData.append("Name", this.cur_Name);
        }
        if (this.cur_Phone) {
          bodyFormData.append("Cell", this.cur_Phone);
        }
        if (this.cur_Status) {
          bodyFormData.append("Status", this.cur_Status);
        }
        if (this.cur_Store) {
          bodyFormData.append("RegLocation", this.cur_Store);
        }      
        if (this.cur_Email) {
          bodyFormData.append("Email", this.cur_Email);
        }
        if (this.cur_MinBalance) {
          bodyFormData.append("MinBalance", this.cur_MinBalance);
        }
        if (this.cur_MaxBalance) {
          bodyFormData.append("MaxBalance", this.cur_MaxBalance);
        }
        bodyFormData.append("offset", offset);
        bodyFormData.append("limit", limit);
        bodyFormData.append("total", this.vip_total);

        var that = this;
        try {
          const response = await axios({
            method: "post",
            url: "/Api/Web/POS/Vip/get_vip_list_full",
            data: bodyFormData,
          });

          if (response.status === 200) {
            var rt = response.data;
            if (rt.status == 0) {
              for(let i=0; i<rt.data.vips.length; i++) {
                that.viplist_full.push(rt.data.vips[i]);
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            resolve(); // Resolve the promise to indicate successful completion
          } else {
            that.$router.push("/login");
            reject(new Error("Failed to fetch VIP list")); // Reject the promise in case of failure
          }
        } catch (error) {
          that.$router.push("/login");
          reject(error); // Reject the promise in case of an exception
        }
      });
    },
    async get_vip_data(init) {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      // show list after locationlist is initialized due to Store column header issue
      if(init){
        this.currpage = '';
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("init", init);
      if (this.cur_CustomerNo) {
        bodyFormData.append("CustomerNo", this.cur_CustomerNo);
      }
      if (this.cur_Name) {
        bodyFormData.append("Name", this.cur_Name);
      }
      if (this.cur_Phone) {
        bodyFormData.append("Cell", this.cur_Phone);
      }
      if (this.cur_Status) {
        bodyFormData.append("Status", this.cur_Status);
      }
      // console.log(this.cur_Store);
      if (this.cur_Store) {
        bodyFormData.append("RegLocation", this.cur_Store);
      }      
      if (this.cur_Email) {
        bodyFormData.append("Email", this.cur_Email);
      }
      if (this.cur_MinBalance) {
        bodyFormData.append("MinBalance", this.cur_MinBalance);
      }
      if (this.cur_MaxBalance) {
        bodyFormData.append("MaxBalance", this.cur_MaxBalance);
      }
      bodyFormData.append("CustomerType", "VIP");
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/Vip",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // Lisa modify for loading the viptypelist and locationlist ---start---
              if(init){
                that.viptypelist = rt.data.viptype;
                that.locationlist = rt.data.locations;
                that.saleslist = rt.data.sales;

                that.locationDict = {};
                that.location_option = [];
                that.location_option[0] = {"LocationID": 0, "LocationName": " "};
                for (let i = 0; i < that.locationlist.length; i++) {
                  that.locationDict[that.locationlist[i].LocationID] = that.locationlist[i];
                  that.location_option.push({
                    "LocationID": that.locationlist[i].LocationID,
                    "LocationName": that.locationlist[i].LocationName,
                  });
                }
                that.currpage = 'list';
              }
              
              that.viplist = rt.data.vips;
              that.vip_total = rt.data.vip_total;
              // that.saleslist = rt.data.sales;
              // that.locationlist = rt.data.locations;
              // Lisa modify for loading the viptypelist and locationlist ---end---
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
              if (rt.message.slice(0,12) == "Solution has") {
                that.$router.push("/vipsetting");
              }
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      // Lisa modify for loading the viptypelist and locationlist ---start---
      // this.get_vip_data();
      this.get_vip_data(1);
    },
    async add_vip() {
      this.currpage = "edit";
      this.cur_vip = JSON.parse(JSON.stringify(this.new_vip));
      this.cur_transcations = [];
      this.address_added = [];
      // this.get_vip_extras();
    },
    async edit_vip(row, column, event) {
      if (!hasUtilityAuth('VIPEdit')) return;
      this.inquiry_cards = [];
      this.currpage = "edit";
      this.cur_vip = JSON.parse(JSON.stringify(row));
      this.cur_transcations = this.cur_vip.cur_transcations;

      // ----------- lisa add start 2022/01/18 for discount-----------
      if (this.cur_vip.VipTypeIDn && this.cur_vip.DiscountPercentage == 0) {
          this.viptype_changed();
        }
      // ----------- lisa add end 2022/01/18 for discount-----------

      // ----------- lisa add start 2022/01/13-----------
      if(this.cur_vip.dob2 && this.cur_vip.dob2.length == 5){
        this.birthday_month = this.cur_vip.dob2.substr(0,2);
        this.birthday_date = this.cur_vip.dob2.substr(3,2);
        this.changeMonth('list');
      }else{
        this.birthday_month = "";
        this.birthday_date = "";
        this.daylist = [];
      }
      // ----------- lisa add end 2022/01/13-----------

      this.address_added = [];
      // this.get_vip_extras();

      // get vip cards
      this.load_cards(this.cur_vip.IDn);
    },
    async load_cards(vip_idn) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("inquiry", JSON.stringify({CustomerIDn:vip_idn}));

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/Card/inquiry",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.inquiry_cards = rt.data.cards;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async added_address(idn) {
      this.address_added.push(idn);
    },
    async deleted_address(idn) {
      for (var i = 0; i < this.address_added.length; i++) {
        if (this.address_added[i] == idn) {
          this.address_added.splice(i, 1);
        }
      }
    },
    async cancel_edit() {
      this.currpage = "list";
      // ----------- lisa add start 2022/01/13-----------
      this.birthday_month = "";
      this.birthday_date = "";
      // ----------- lisa add end 2022/01/13-----------
    },
    async submit_vip() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);
      bodyFormData.append("address_added", this.address_added);
      this.address_added = [];

      for (var key in this.cur_vip) {
        if (this.cur_vip[key]) {
          bodyFormData.append(key, this.cur_vip[key]);
        }
      }
      // if (!bodyFormData.has("IDn")) {
      //   bodyFormData.append("IDn", 0);
      // }
      // ----------- lisa add start 2022/01/13-----------
      if(this.birthday_month && this.birthday_date){
        bodyFormData.append("dob2", this.birthday_month + "-" + this.birthday_date);
      } else {
        bodyFormData.append("dob2", "");
      }
      // ----------- lisa add end 2022/01/13-----------
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/Vip/submit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.viplist = rt.data;
              that.currpage = "list";
              // that.cur_opt = "list";
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    async fillup_sheet() {
      this.sheets = [{name: "Sheet1", data: []}];
      // Init 2 dimensional array
      var dataarr = [];
      for (var i = 0; i < this.vip_total+1; i++) {    // +1 because the top row is for headers
        dataarr[i] = [];
      }

      try {
        dataarr[0][0] = "VIP No";
        dataarr[0][1] = "VIP Type";
        dataarr[0][2] = "Name";
        dataarr[0][3] = "Phone";
        dataarr[0][4] = "Store";
        dataarr[0][5] = "Status";
        dataarr[0][6] = "Email";
        dataarr[0][7] = "Balance";
        dataarr[0][8] = "Points";
        var row = 1;
        for(let i=0; i<this.viplist_full.length; i++) {
          dataarr[row][0] = this.viplist_full[i].CustomerNo;
          dataarr[row][1] = this.viplist_full[i].CustomerType;
          dataarr[row][2] = this.viplist_full[i].FirstName + " " + this.viplist_full[i].LastName;
          dataarr[row][3] = this.viplist_full[i].Cell;
          dataarr[row][4] = this.viplist_full[i].RegLocation;
          dataarr[row][5] = this.viplist_full[i].Status==1?'Active':'Unconfirm';
          dataarr[row][6] = this.viplist_full[i].EMail;
          dataarr[row][7] = this.viplist_full[i].Balance;
          dataarr[row][8] = this.viplist_full[i].Points;
          row++;
        }
      } catch (err) {
        // console.log("fillup_sheet err",err);
      }
      var sheetname = "Customers List";
      this.sheets = [{ name: sheetname, data: dataarr }];
    },
    format_date(date) {
      // function join(t, a, s) {
      //   function format(m) {
      //     let f = new Intl.DateTimeFormat('en', m);
      //     return f.format(t);
      //   }
      //   return a.map(format).join(s);
      // }
      // let a1 = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}];
      // let s1 = join(date, a1, '-');
      // let a2 = [{hour: '2-digit', hour12: false}, {minute: '2-digit'}, {second: '2-digit'}];
      // let s2 = join(date, a2, ':');
      // return s1 + ' ' + s2;
      let p = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      })
        .formatToParts(date)
        .reduce((acc, part) => {
          acc[part.type] = part.value;
          return acc;
        }, {});
      return `${p.year}-${p.month}-${p.day} ${p.hour}:${p.minute}:${p.second}`;
    },
  },
  created() {
    this.token = getStore("token");
    this.user_id = getStore("user_id");
  },
  mounted() {
    this.init_data();
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
.vippage .el-collapse-item__header {
  background-color: #a2cdff;
  /* border-top-left-radius: 1rem; */
  /* border-top-right-radius: 1rem; */
  height: 1.6rem;
  font-size: 1.2rem;
  padding-left: 1rem;
}
.vippage th div {
  line-height: 1.6 !important;
}
.vippage div {
  font-size: 1rem;
  line-height: 2 !important;
}
.vippage .table .thead-light th {
  border-left-color: #e0e0e0;
  border-right-color: #e0e0e0;
}
.vippage .table td {
  padding: 0px 0px 0px 1rem;
  border-left-color: #e0e0e0;
  border-right-color: #e0e0e0;
}
.vippage hr {
  margin: 0.5rem 2rem;
}
.vippage select {
  border: 1px solid #cccccc;
  height: 1.6rem;
  width: 90%;
  border-radius: 5px;
  background-color: #fff;
  color: #888;
}
.vip-disabled {
  background-color: #e8e8e8 !important;
}
.vip-input {
  border: 1px solid #cccccc;
  height: 1.6rem;
  width: 100%;
  border-radius: .1rem;
  background-color: #fff;
  color: #888;
}
.vippage .el-card__header {
  padding: 0px 0px 0px 1rem;
}
.vippage .el-card__body {
  padding: 0px 1rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
