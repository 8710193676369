import { render, staticRenderFns } from "./Pos_transaction_report.vue?vue&type=template&id=d54b4a4c&scoped=true&"
import script from "./Pos_transaction_report.vue?vue&type=script&lang=js&"
export * from "./Pos_transaction_report.vue?vue&type=script&lang=js&"
import style0 from "./Pos_transaction_report.vue?vue&type=style&index=0&id=d54b4a4c&prod&scope=true&lang=css&"
import style1 from "./Pos_transaction_report.vue?vue&type=style&index=1&id=d54b4a4c&prod&lang=css&"
import style2 from "bootstrap/dist/css/bootstrap.css?vue&type=style&index=2&id=d54b4a4c&prod&scoped=true&lang=css&"
import style3 from "bootstrap-vue/dist/bootstrap-vue.css?vue&type=style&index=3&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d54b4a4c",
  null
  
)

export default component.exports