<template>
  <div class="tpl-class" @click="setTemplate">
    <div :style="departmentStyle" class="block-class deparment">
      {{ $t("Department") }}
    </div>
    <div :style="categoryStyle" class="block-class category">
      {{ $t("Category") }}
    </div>
    <div :style="productStyle" class="block-class product">
      {{ $t("Product") }}
    </div>
  </div>
</template>
<script>
  export default {
    name: 'MenuTemplate',
    props: {
      tplColors: {
        required: true,
        default: false,
      },
    },
    data() {
      return {
        departmentStyle: {
          backgroundColor:'#F2B8A4',
        },
        categoryStyle: {
          backgroundColor:'#F2DBB4',
        },
        productStyle: {
          backgroundColor:'#FEFAF7',
        },
      };
    },
    methods: {
      setTemplate() {
        this.$emit('tplsave', this.tplColors);
      },
      fillColors() {
        this.departmentStyle.backgroundColor = this.tplColors.department;
        this.categoryStyle.backgroundColor = this.tplColors.category;
        this.productStyle.backgroundColor = this.tplColors.product;
      },
    },
    mounted() {
      // console.log("MenuTemplate.vue mounted", this.tplColors);
      this.fillColors();
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside);
    }
  };
</script>
<style>
  .tpl-class {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid;
  }
  .deparment {
    flex: 1;
  }
  .category {
    flex: 2;
  }
  .product {
    flex: 3;
  }
  .block-class {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1rem;
    border: 1px solid;
    border-style: outset;
    width: 100%;
    text-align: center;
  }
</style>